import React, { useEffect, useState } from 'react';
import axios from 'axios';
import defaultUserIcon from '../../images/HeaderImages/defaultUserIcon.png';
import { Navbar, Nav, DropdownButton, Dropdown, Image } from 'react-bootstrap';
import styles from './Header.module.css';
import PepsicoLogo from '../../images/HeaderImages/PepsicoLogo.png';
import { isProd } from '../../config/powerBiReportDetails';
import { msalLogout } from '../../config/msalConfig';

// Header section with Report Name, User Profile
const Header: React.FC = () => {
  const [imageUrl, setImageUrl] = useState(defaultUserIcon);

  /* 
    Description: Get user image from Microsoft Graph API using Access Token
  */
  const getImage = () => {
    const token = sessionStorage.getItem('msalAccessToken');
    axios({
      url: 'https://graph.microsoft.com/beta/me/photo/$value',
      method: 'GET',
      timeout: 0,
      responseType: 'arraybuffer',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        const arrayBufferView = new Uint8Array(response.data);
        const blob = new Blob([arrayBufferView], {
          type: 'image/jpeg',
        });
        const urlCreator = window.URL || window.webkitURL;
        const imageURL = urlCreator.createObjectURL(blob);
        setImageUrl(imageURL);
      })

      // In case of an error or unavailability of message, display a default user image
      .catch(() => {
        setImageUrl(defaultUserIcon);
      });
  };

  useEffect(() => {
    getImage();
  }, []);

  return (
    <Navbar className={styles.bsrHeader}>
      <Navbar.Brand id={styles.navBrand}>
        <img
          src={PepsicoLogo}
          alt='Pepsico Logo'
          className={styles.pepsiLogo}
        />
        <span className={styles.headerTitle}>&#124;</span>
        <span className={styles.headerTitle}>
          BSR Report {!isProd && '(Dev Environment)'}
        </span>
      </Navbar.Brand>
      <Nav className='ml-auto'>
        <DropdownButton
          menuAlign='right'
          title={
            <>
              <Image
                className={styles.profileImage + ' mr-2'}
                src={imageUrl}
                alt='Profile Picture'
                roundedCircle
              />
            </>
          }
          id={styles.dropdownMenu}
        >
          <Dropdown.Item className={styles.userName} disabled>
            {sessionStorage.getItem('userName')}
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={msalLogout}>Log out</Dropdown.Item>
        </DropdownButton>
      </Nav>
    </Navbar>
  );
};

export default Header;
